import React, { useEffect, useRef } from 'react'
import { Button } from 'primereact/button';
import map from '../../res/img/map.png'
import img1 from '../../res/img/img1.jpg'
import img2 from '../../res/img/img2.jpg'
import img3 from '../../res/img/img3.jpg'
import img4 from '../../res/img/img4.jpg'
import img5 from '../../res/img/img5.jpg'
import img6 from '../../res/img/img6.jpg'
import img7 from '../../res/img/img7.jpg'
import img8 from '../../res/img/img8.jpg'
import img9 from '../../res/img/img9.jpg'
import img10 from '../../res/img/img10.jpg'
import { useMountEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';

const Home = () => {
    const msgs = useRef(null);
    const msgsDesktop = useRef(null);

    useMountEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            // msgs.current.show({ id: '1', sticky: true, severity: 'info', detail: 'Вітаємо нового президента школи Марченка Тимофія Денисовича!', closable: false });
        }
        if (msgsDesktop.current) {
            msgsDesktop.current.clear();
            // msgsDesktop.current.show({ id: '1', sticky: true, severity: 'info', detail: 'Вітаємо нового президента школи Марченка Тимофія Денисовича!', closable: true });
        }
    });
    return(
        <>
            <div className="md:hidden">
                <div className="legend">
                    <p style={{ paddingTop: '5em' }}>
                        <span style={{ color: 'white', fontSize: '2.5em' }}>Школа вашого</span>
                        <br />
                        <span style={{ color: 'white', fontSize: '2.5em' }}>майбутнього</span>
                    </p>
                </div>
                <Messages ref={msgs} />
                <div className="about">
                    <span>
                        <span className="about-heading">
                            Трішки про школу
                        </span>
                        <br /><br />
                        <span className="about-text">
                            Серед вуличок Оболонського району Києва розташована спеціалізована школа №216. Вона є центром знань та розвитку, відома своєю академічною ефективністю. Школа дбає про гармонійний розвиток особистості учнів, завдяки вчителям та сучасному підходу до навчання. Учні мають можливість розкрити свій потенціал у різних сферах – від науки до мистецтва. Школа №216 на Оболоні – це спільнота, що формує цінності, дружні стосунки та незабутні спогади про навчання, готуючи до майбутнього.
                        </span>
                        <div style={{ height: '1em' }} />
                    </span>
                </div>
                <img loading='lazy' src={img4} style={{ height: '16em', width: '100%', objectFit: 'cover' }} />
                <br /><br /><br />
                <img loading='lazy' src={img10} style={{ height: '16em', width: '100%', objectFit: 'cover' }} />
                <br /><br /><br />
                <img loading='lazy' src={img9} style={{ height: '16em', width: '100%', objectFit: 'cover' }} />
                <br /><br /><br />
                <div className="location">
                    <span>
                        <span className="location-heading">
                            Як нас знайти?
                        </span>
                        <br /><br />
                        <span className="location-adress">
                            Ми знаходимося за адресою:<br /> <span style={{ fontSize: '1.2em', color: '#FFDB4D' }}>Вулиця Олександра Архипенка, 8Г</span>
                        </span>
                        <div style={{ height: '1em' }} />
                        <a className="location-button" href="https://goo.gl/maps/Ny9kH9S6Me2ajnic6">
                            <Button label="Переглянути у google maps" rounded />
                        </a>
                    </span>
                </div>
                <img loading='lazy' src={img2} style={{ height: '16em', width: '100%', objectFit: 'cover' }} />
                <br /><br /><br />
                <img loading='lazy' src={img5} style={{ height: '16em', width: '100%', objectFit: 'cover' }} />
                <br /><br /><br />
                <img loading='lazy' src={img7} style={{ height: '16em', width: '100%', objectFit: 'cover' }} />
                <br /><br /><br />
                <div className="facilities">
                    <div className="surface-0 text-center">
                        <div className="mb-3 font-bold text-3xl">
                            <span className="text-900">Переваги </span>
                            <span className="text-blue-600">СШ №216</span>
                        </div>
                        <div className="text-700 mb-6">Наші переваги над іншими навчальними закладами</div>
                        <div className="grid">
                            <div className="col-12 md:col-4 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-book text-4xl text-blue-500"></i>
                                </span>
                                <div className="text-900 text-xl mb-3 font-medium">
                                    Просунута навчальна база
                                </div>
                                <span className="text-700 line-height-3">У нашій школі існує передова навчальна база, створена для нашого розвитку. Сучасні лабораторії, технології та доступ до онлайн-ресурсів надають нам відмінні можливості для активного навчання. Це сприяє глибокому засвоєнню знань та розвитку навичок, що допоможуть нам у майбутньому.</span>
                            </div>
                            <div className="col-12 md:col-4 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-globe text-4xl text-blue-500"></i>
                                </span>
                                <div className="text-900 text-xl mb-3 font-medium">
                                    Англійська з першого класу
                                </div>
                                <span className="text-700 line-height-3">З першого класу ми акцентуємо увагу на вивченні англійської мови. Інтерактивні уроки, захоплюючі вправи та новітні навчальні засоби створюють стимулююче середовище для розвитку комунікативних навичок та мовної компетенції.</span>
                            </div>
                            <div className="col-12 md:col-4 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-users text-4xl text-blue-500"></i>
                                </span>
                                <div className="text-900 text-xl mb-3 font-medium">
                                    Дружній вчительський склад
                                </div>
                                <span className="text-700 line-height-3">У нашій школі панує дружня атмосфера серед вчителів. Мета — надати учням найкращі можливості для навчання й розвитку. Взаємопідтримка, ідеї та колективна робота створюють невимушене середовище, де вчителі навчаються та вдосконалюють свої навички. Дружність вчителів сприяє гармонійному розвитку учнів.</span>
                            </div>
                            <div className="col-12 md:col-4 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-lock text-4xl text-blue-500"></i>
                                </span>
                                <div className="text-900 text-xl mb-3 font-medium">
                                    Безпека та оновлене укриття
                                </div>
                                <span className="text-700 line-height-3">Нещодавно ми створили нове укриття зі свіжим ремонтом в нашій школі. Це сучасне приміщення надає надійний захист учням та співробітникам у випадку надзвичайних ситуацій. Покладаючи особливий акцент на комфорт і безпеку, ми забезпечили просторий і зручний простір для всіх присутніх.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:block">
                <Messages style={{ paddingTop: '0.05em' }} ref={msgsDesktop} />
                <div className="legendDesktop">
                    <p>
                        <span style={{ color: 'white', fontSize: '7em' }}>Школа вашого</span>
                        <br />
                        <span style={{ color: 'white', fontSize: '7em' }}>майбутнього</span>
                        <br /><br /><br /><br />
                        <i style={{ color: 'white', fontSize: '3em' }} className="pi pi-angle-down"></i>
                    </p>
                </div>
                <div className="about" style={{ marginTop: '2em' }}>
                    <span style={{ fontSize: '2.3em' }}>
                            Трішки про школу
                        </span>
                    <br /><br /><br />
                    <div style={{ width: '75%', margin: '0 auto' }}>
                        <span style={{ fontSize: '1.5em' }}>
                            Серед вуличок Оболонського району Києва розташована спеціалізована школа №216. Вона є центром знань та розвитку, відома своєю академічною ефективністю. Школа дбає про гармонійний розвиток особистості учнів, завдяки вчителям та сучасному підходу до навчання. Учні мають можливість розкрити свій потенціал у різних сферах – від науки до мистецтва. Школа №216 на Оболоні – це спільнота, що формує цінності, дружні стосунки та незабутні спогади про навчання, готуючи до майбутнього.
                        </span>
                    </div>
                </div>
                <br />
                <div style={{ width: '100%', height: '18em', padding: '1em', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <img loading='lazy' src={img2} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />
                    <img loading='lazy' src={img3} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />
                    <img loading='lazy' src={img4} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />

                </div>
                <div style={{ width: '100%', height: '18em', padding: '1em', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <img loading='lazy' src={img5} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />
                    <img loading='lazy' src={img7} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />
                    <img loading='lazy' src={img6} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />

                </div>
                <div style={{ width: '100%', height: '18em', padding: '1em', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <img loading='lazy' src={img8} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />
                    <img loading='lazy' src={img1} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />
                    <img loading='lazy' src={img9} style={{ height: '16em', width: '28em', objectFit: 'cover' }} />

                </div>
                <div className="location-desktop" style={{ marginTop: '3em' }}>

                        <br /><br /><br />
                        <div style={{ height: '75%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '50%' }}>
                                <a href="https://goo.gl/maps/Ny9kH9S6Me2ajnic6"><img src={map} height='400em' /></a>
                            </div>
                           <div style={{ width: '50%' }}>
                               <span className="location-heading-desktop">
                                    Як нас знайти?
                               </span>
                               <br /><br /><br />
                               <span className="location-adress-desktop">
                                    Ми знаходимося за адресою:<br /> <span style={{ fontSize: '1.2em', color: '#FFDB4D' }}>Вулиця Олександра<br />Архипенка, 8Г</span>
                               </span>
                               <div style={{ height: '1em' }} />
                               <br /><br />
                               <a className="location-button-desktop" href="https://goo.gl/maps/Ny9kH9S6Me2ajnic6">
                                   <Button label="Переглянути у google maps" rounded />
                               </a>
                           </div>
                        </div>
                </div>
                <div style={{ fontSize: '2em', textAlign: 'center', marginTop: '1.5em' }}>Наші переваги над іншими навчальними закладами</div>
                <div className="grid" style={{ marginTop: '3em' }}>
                    <div className="col-12 md:col-3 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-book text-4xl text-blue-500"></i>
                                </span>
                        <div className="text-900 text-xl mb-3 font-medium">
                            Просунута навчальна база
                        </div>
                        <span className="text-700 line-height-3">У нашій школі існує передова навчальна база, створена для нашого розвитку. Сучасні лабораторії, технології та доступ до онлайн-ресурсів надають нам відмінні можливості для активного навчання. Це сприяє глибокому засвоєнню знань та розвитку навичок, що допоможуть нам у майбутньому.</span>
                    </div>
                    <div className="col-12 md:col-3 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-users text-4xl text-blue-500"></i>
                                </span>
                        <div className="text-900 text-xl mb-3 font-medium">
                            Дружній вчительський склад
                        </div>
                        <span className="text-700 line-height-3">У нашій школі панує дружня атмосфера серед вчителів. Мета — надати учням найкращі можливості для навчання й розвитку. Взаємопідтримка, ідеї та колективна робота створюють невимушене середовище, де вчителі навчаються та вдосконалюють свої навички. Дружність вчителів сприяє гармонійному розвитку учнів.</span>
                    </div>
                    <div className="col-12 md:col-3 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-lock text-4xl text-blue-500"></i>
                                </span>
                        <div className="text-900 text-xl mb-3 font-medium">
                            Безпека та оновлене укриття
                        </div>
                        <span className="text-700 line-height-3">Нещодавно ми створили нове укриття зі свіжим ремонтом в нашій школі. Це сучасне приміщення надає надійний захист учням та співробітникам у випадку надзвичайних ситуацій. Покладаючи особливий акцент на комфорт і безпеку, ми забезпечили просторий і зручний простір для всіх присутніх.</span>
                    </div>
                    <div className="col-12 md:col-3 mb-4 px-5">
                                <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                                    <i className="pi pi-globe text-4xl text-blue-500"></i>
                                </span>
                        <div className="text-900 textv-xl mb-3 font-medium">
                            Англійська з першого класу
                        </div>
                        <span className="text-700 line-height-3">З першого класу ми акцентуємо увагу на вивченні англійської мови. Інтерактивні уроки, захоплюючі вправи та новітні навчальні засоби створюють стимулююче середовище для розвитку комунікативних навичок та мовної компетенції.</span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Home