import React from "react";
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import {Link} from "react-router-dom";


const NotFound = () => {
    return(
        <>
            <div style={{ textAlign: 'center', marginTop: '1em' }}>
                <Message severity="error" text="Сторінку не знайдено(404)" />
                <br /><br />
                <Link to='/'><Button>На головну</Button></Link>
            </div>
            <div style={{ height: '100em' }} />
        </>
    )
}

export default NotFound