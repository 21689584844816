import React, { useState } from "react";
import { Sidebar } from 'primereact/sidebar';

const MenuListDesktop = (item) => {
    const [visible, setVisible] = useState(false);

    const createMenu = (item) => {
        if(item.type === 'listItem') {
            return (
                <a href="#" style={{color: 'black', textDecoration: 'none'}} onClick={() => window.location.replace(item.link) }>
                    <h4 style={{ marginBottom: '-0.3em' }}>{item.displayName}</h4></a>
            )
        }
    }

    return (
        <>
            <a href="#" style={{color: 'black', textDecoration: 'none' }} onClick={() => setVisible(true)}><h4>{item.item.displayName} ></h4></a>
            <Sidebar visible={visible} onHide={() => setVisible(false)}>
                <h2 style={{ marginTop: '-0.2em' }}>{item.item.displayName}</h2>
                { item.item.items.map((item) => createMenu(item)) }
            </Sidebar>
        </>
    )
}

export default MenuListDesktop