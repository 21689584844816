import React, {useState} from "react";
import {serverUrl} from "./Api";
import logo from "../../res/img/logo.png";

const PageRender = () => {
    const [page, setPage] = useState([]);
    const [fetched, setFetched] = useState(false);

    if(fetched === false) {
        fetch(serverUrl + '/pages/' + window.location.pathname.split('/')[1])
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setPage(data[0])
                setFetched(true)
            });
    }

    if(fetched === true) {
        if(page !== undefined){
            return (
                <>
                    <div className="legendPage">
                        <p>
                            <span style={{ fontSize: '2em' }}>{page.title}</span>
                        </p>
                    </div>
                    <div style={{ minHeight: '80vh' }} className="newsView-container" dangerouslySetInnerHTML={{__html: page.content}} />
                </>
            )
        } else{
            window.location.replace('/')
        }

    } else{
        return (
            <>
                <div style={{ height: '30vh' }} />
                <center>
                    <i><img className='pi-spin' src={logo} height='150px'/></i>
                </center>
                <div style={{ height: '50vh' }} />
            </>
        )
    }
}

export default PageRender