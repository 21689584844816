import React, {useState} from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Button} from "primereact/button";
import {serverUrl} from "./Api";
import axios, {create} from "axios";
import {Dialog} from "primereact/dialog";
import {Editor} from "primereact/editor";
import {SpeedDial} from "primereact/speeddial";
import {Avatar} from "primereact/avatar";
import {InputText} from "primereact/inputtext";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import {BlockUI} from "primereact/blockui";
import { ListBox } from 'primereact/listbox';
import logo from "../../res/img/logo.png";
import {direction} from "quill/ui/icons";
import {FileUpload} from "primereact/fileupload";
import { uid } from 'uid';
import {Dropdown} from "primereact/dropdown";
import {Card} from "primereact/card";

const Admin = () => {

    const [connection, setConnection] = useState(false);
    const [connected, setConnected] = useState(false);

    const checkConnection = () => {
        if(connected === false)
        fetch(serverUrl )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setConnection(true)
                setConnected(true)
            })
            .catch(error => {
                setConnection(false)
            });
    }

    checkConnection()

    const [uiBlock, setUiBlock] = useState(false);

    const [createSessionLaunched, setCreateSessionLaunched] = useState(false);

    const [authenticated, setAuthenticated] = useState(false);

    const [auth, setAuth] = useState(false);
    const [mode, setMode] = useState(('none'));
    const [name, setName] = useState(('none'));

    const [imageUploadModal, setImageUploadModal] = useState(false);
    const [fileUid, setFileUid] = useState("");

    const [newsEditorModal, setNewsEditorModal] = useState(false);

    const [newsEditorEditModal, setNewsEditorEditModal] = useState(false);

    const [newsEditorModalEditId, setNewsEditorModalEditId] = useState(0);

    const [newsEditorModalTitle, setNewsEditorModalTitle] = useState('')
    const [newsEditorModalDate, setNewsEditorModalDate] = useState('')
    const [newsEditorModalShort, setNewsEditorModalShort] = useState('')
    const [newsEditorModalText, setNewsEditorModalText] = useState('')
    const [newsEditorModalGallery, setNewsEditorModalGallery] = useState({})

    const [pagesEditorModal, setPagesEditorModal] = useState(false);

    const [pagesEditorEditModal, setPagesEditorEditModal] = useState(false);

    const [pagesEditorModalTitle, setPagesEditorModalTitle] = useState('')
    const [pagesEditorModalLink, setPagesEditorModalLink] = useState('')
    const [pagesEditorModalText, setPagesEditorModalText] = useState('')

    const [pagesEditorModalEditId, setPagesEditorModalEditId] = useState(0);

    const [menuItemAddSelectTypeModal,setMenuItemAddSelectTypeModal] = useState(false);
    const [menuItemAddLinkModal,setMenuItemAddLinkModal] = useState(false);
    const [menuItemAddListModal,setMenuItemAddListModal] = useState(false);

    const [menuListItemAddLinkModal,setMenuListItemAddLinkModal] = useState(false);
    const [menuListItemEditLinkModal,setMenuListItemEditLinkModal] = useState(false);

    const [menuItemEditLinkModal,setMenuItemEditLinkModal] = useState(false);
    const [menuItemEditListModal,setMenuItemEditListModal] = useState(false);

    const [menuLinkEditorTitle, setMenuLinkEditorTitle] = useState('')
    const [menuLinkEditorUri, setMenuLinkEditorUri] = useState('')

    const [menuListEditorTitle, setMenuListEditorTitle] = useState('')

    const [newsItems, setNewsItems] = useState([]);
    const [fetched1, setFetched1] = useState(false);

    const [pages, setPages] = useState([]);
    const [fetched2, setFetched2] = useState(false);

    const [menu, setMenu] = useState([]);
    const [fetched3, setFetched3] = useState(false);

    // const [users, setUsers] = useState([]);
    // const [fetched4, setFetched4] = useState(false);

    const [galleries, setGalleries] = useState([])

    const [galleriesEditorModal, setGalleriesEditorModal] = useState(false)
    const [galleriesEditorModalName, setGalleriesEditorModalName] = useState('')

    const [fetched6, setFetched6] = useState(false);

    let params = new URLSearchParams(document.location.search);

    const endSession = () => {
        axios.patch(serverUrl + '/sessionEnd', {
            sessionId: window.localStorage.getItem('sessionId')
        })
            .then(() => {
                window.location.reload()
            })
    }

    if(params.get('loginData') === 'yes') {
        if(createSessionLaunched === false){
            setCreateSessionLaunched(true)
            axios.post(serverUrl + '/sessionCreate', {
                username: params.get('username'),
                password:params.get('password')
            })
                .then(function (response) {
                    if(response.data.result === 'success'){
                        window.localStorage.setItem('sessionId', response.data.sessionId)
                        window.localStorage.setItem('token', response.data.token)
                        window.location.replace('/administration')
                    } else{
                        window.location.replace('/administration')
                    }
                })
        }
    } else if(authenticated === false){
        axios.patch(serverUrl + '/sessionCheck', {
            sessionId: window.localStorage.getItem('sessionId'),
            token: window.localStorage.getItem('token')
        })
            .then(function (response) {
                setAuthenticated(true)
                if(response.data.result === 'success'){
                    setMode(response.data.access)
                    setName(response.data.name)
                    setAuth(true)
                } else{
                    setAuth(false)
                }
            })
    }
    const fetchNews = () => {
        if (fetched1 === false) {
            fetch(serverUrl + '/articlesAll')
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setNewsItems(data)
                    setFetched1(true)
                });
        }
    }

    const fetchGalleries = () => {
        if (fetched6 === false) {
            fetch(serverUrl + '/galleries')
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setGalleries(data)
                    setFetched6(true)
                });
        }
    }

    const fetchPages = () => {
        if (fetched2 === false) {
            fetch(serverUrl + '/pagesAll')
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setPages(data)
                    setFetched2(true)
                });
        }
    }

    const fetchMenu = () => {
        if (fetched3 === false) {
            fetch(serverUrl + '/menu')
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setMenu(data)
                    setFetched3(true)
                });
        }
    }



    const confirmArticleDelete = (data) => {
        confirmDialog({
            message: 'Ви справді хочете видалити цю новину?',
            header: 'Необхідне підтвердження',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectLabel: 'Ні',
            acceptLabel: 'Так',
            draggable: false,
            accept() {
                setUiBlock(true)
                axios.patch(serverUrl + '/articleDelete', {
                    id: data.id,
                    sessionId: window.localStorage.getItem('sessionId'),
                    token: window.localStorage.getItem('token')
                })
                    .then(function (response) {
                        setUiBlock(false)
                        window.location.reload()
                    })
            }
        });
    };

    const confirmPageDelete = (data) => {
        confirmDialog({
            message: 'Ви справді хочете видалити цю cторінку назавжди?',
            header: 'Необхідне підтвердження',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectLabel: 'Ні',
            acceptLabel: 'Так',
            draggable: false,
            accept() {
                setUiBlock(true)
                axios.patch(serverUrl + '/pageDelete', {
                    id: data.id,
                    sessionId: window.localStorage.getItem('sessionId'),
                    token: window.localStorage.getItem('token')
                })
                    .then(function (response) {
                        setUiBlock(false)
                        window.location.reload()
                    })
            }
        });
    };

    const createStatusLabel = (data) => {
        if(data.status === 'active'){
            if(mode === 'master' || mode === 'admin' || mode === 'supervisor') {
                return (
                    <Tag severity="success" value="Опубліковано"></Tag>
                )
            } else{
                return (
                    <>
                        <Tag severity="success" value="Опубліковано"></Tag>
                        <Button onClick={() => {
                        window.open('/news?id=' + data.id).focus()
                    }} severity='info' style={{height: '0.25em', marginLeft: '0.1em'}}><i
                        className='pi pi-eye'/></Button>
                    </>
                )
            }
        } else if(data.status === 'revision'){
            if(mode === 'master' || mode === 'admin' || mode === 'supervisor') {
                return (
                    <>
                        <Tag severity="warning" value="На перевірці"></Tag><Button onClick={() => {
                            setUiBlock(true)
                        axios.patch(serverUrl + '/articleApprove', {
                            id: data.id,
                            sessionId: window.localStorage.getItem('sessionId'),
                            token: window.localStorage.getItem('token')
                        })
                            .then(function (response) {
                                setUiBlock(false)
                                window.location.reload()
                            })
                    }} severity='success' style={{height: '0.25em', marginLeft: '0.1em'}}><i
                        className='pi pi-check'/></Button><Button onClick={() => {
                        setUiBlock(true)
                        axios.patch(serverUrl + '/articleDecline', {
                            id: data.id,
                            sessionId: window.localStorage.getItem('sessionId'),
                            token: window.localStorage.getItem('token')
                        })
                            .then(function (response) {
                                setUiBlock(false)
                                window.location.reload()
                            })
                    }} severity='danger' style={{height: '0.25em', marginLeft: '0.1em'}}><i
                        className='pi pi-times'/></Button>
                    </>
                )
            } else{
                return (
                    <>
                        <Tag severity="warning" value="На перевірці"></Tag>
                    </>
                )
            }
        } else if(data.status === 'declined'){
            return(
                <Tag severity="danger" value="Відхилено"></Tag>
            )
        } else if(data.status === 'deleted'){
            return(
                <p style={{ color: 'red' }}>Видалено</p>
            )
        }
    }

    const newsActions = (data) => {
        if(mode === 'master' || mode === 'admin' || mode === 'supervisor') {
            if (data.status === 'active') {
                return (
                    <div style={{ minWidth: '15em' }}>
                        <Button onClick={() => {
                            window.open('/news?id=' + data.id).focus()
                        }} label={<i className='pi pi-eye'/>} severity='info' style={{margin: '0.12em'}}/>
                        <Button label={<i className='pi pi-pencil'/>} onClick={() => {
                            setUiBlock(true)
                            fetch(serverUrl + '/articles/' + data.id)
                                .then((response) => {
                                    return response.json();
                                })
                                .then((data) => {
                                    setNewsEditorModalTitle(data[0].title)
                                    setNewsEditorModalDate(data[0].date)
                                    setNewsEditorModalShort(data[0].subtitle)
                                    setNewsEditorModalText(data[0].content)
                                    setNewsEditorModalGallery(data[0].gallery)
                                    setNewsEditorModalEditId(data[0].id)
                                    setUiBlock(false)
                                    setNewsEditorEditModal(true)
                                });
                        }} severity='warning' style={{margin: '0.12em'}}/>
                        <Button onClick={() => {confirmArticleDelete(data)}} label={<i className='pi pi-times-circle'/>} severity='danger'
                                style={{margin: '0.12em'}}/>
                    </div>
                )
            } else if (data.status === 'revision') {
                return (
                    <>
                        <Button label={<i className='pi pi-pencil'/>} onClick={() => {
                            setUiBlock(true)
                            fetch(serverUrl + '/articles/' + data.id)
                                .then((response) => {
                                    return response.json();
                                })
                                .then((data) => {
                                    setNewsEditorModalTitle(data[0].title)
                                    setNewsEditorModalDate(data[0].date)
                                    setNewsEditorModalShort(data[0].subtitle)
                                    setNewsEditorModalText(data[0].content)
                                    setNewsEditorModalEditId(data[0].id)
                                    setNewsEditorModalGallery(data[0].gallery)
                                    setUiBlock(false)
                                    setNewsEditorEditModal(true)
                                });
                        }} severity='warning' style={{margin: '0.12em'}}/>
                    </>
                )
            } else if (data.status === 'declined') {
                return (
                    <>
                        <Button disabled onClick={() => {
                            window.open('/news?id=' + data.id).focus()
                        }} label={<i className='pi pi-eye'/>} severity='info' style={{margin: '0.12em'}}/>
                        <Button disabled label={<i className='pi pi-pencil'/>} severity='warning'
                                style={{margin: '0.12em'}}/>
                        <Button onClick={() => {confirmArticleDelete(data)}} label={<i className='pi pi-times-circle'/>} severity='danger'
                                style={{margin: '0.12em'}}/>
                    </>
                )
            } else if(data.status === 'deleted') {
                return(
                    <>
                    <Button onClick={() => {
                        setUiBlock(true)
                        axios.patch(serverUrl + '/articleRenew', {
                            id: data.id,
                            sessionId: window.localStorage.getItem('sessionId'),
                            token: window.localStorage.getItem('token')
                        })
                            .then(function (response) {
                                setUiBlock(false)
                                window.location.reload()
                            })
                    }} severity='success' style={{height: '0.25em', marginLeft: '0.1em'}}>Відновити</Button>
                        <Button onClick={() => {
                            setUiBlock(true)
                            axios.patch(serverUrl + '/articlePermanentDelete', {
                                id: data.id,
                                sessionId: window.localStorage.getItem('sessionId'),
                                token: window.localStorage.getItem('token')
                            })
                                .then(function (response) {
                                    setUiBlock(false)
                                    window.location.reload()
                                })
                        }} severity='danger' style={{height: '0.25em', marginLeft: '0.1em'}}>Знищити</Button>
                    </>
                )
            } else {
                return (
                    <>
                        - - -
                    </>
                )
            }
        } else{
            return(
                <>
                    <i className='pi pi-lock'/>
                </>
            )
        }

    }

    const handleMenuAddItem = () => {
        setMenuLinkEditorTitle('')
        setMenuLinkEditorUri('')
        setMenuListEditorTitle('')
        setMenuItemAddSelectTypeModal(true)
    }

    const handleMenuLink = (id, name, link) => {
        window.sessionStorage.setItem('currentId', id)
        setMenuLinkEditorTitle(name)
        setMenuLinkEditorUri(link)
        setMenuItemEditLinkModal(true)
    }

    const handleMenuList = (id, name) => {
        window.sessionStorage.setItem('currentId', id)
        setMenuLinkEditorTitle(name)
        setMenuItemEditListModal(true)
    }

    const handleMenuListItem = (id, name, link) => {
        window.sessionStorage.setItem('currentId', id)
        setMenuLinkEditorTitle(name)
        setMenuLinkEditorUri(link)
        setMenuListItemEditLinkModal(true)
    }

    const handleMenuListAddItem = (id, name, link) => {
        window.sessionStorage.setItem('currentId', id)
        setMenuLinkEditorTitle(name)
        setMenuLinkEditorUri(link)
        setMenuListItemAddLinkModal(true)
    }

    const deleteMenuItem = (id) => {
        confirmDialog({
            message: 'Видалити цей елемент?',
            header: 'Необхідне підтвердження',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectLabel: 'Ні',
            acceptLabel: 'Так',
            draggable: false,
            accept() {
                setUiBlock(true)
                axios.patch(serverUrl + '/menuItemDelete', {
                    id: id,
                    sessionId: window.localStorage.getItem('sessionId'),
                    token: window.localStorage.getItem('token')
                })
                function reloadScreen() {
                    window.location.reload()
                }
                setTimeout(reloadScreen, 1000);
            }
        });

    }

    const pagesActions = (data) => {
        return(
            <>
                <Button onClick={() => {
                    window.open('/' + data.link).focus()
                }} label={<i className='pi pi-eye'/>} severity='info' style={{margin: '0.12em'}}/>
                <Button label={<i className='pi pi-pencil'/>} onClick={() => {
                    setUiBlock(true)
                    fetch(serverUrl + '/pages/' + data.link)
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            setPagesEditorModalTitle(data[0].title)
                            setPagesEditorModalText(data[0].content)
                            setPagesEditorModalEditId(data[0].id)
                            setPagesEditorEditModal(true)
                            setUiBlock(false)
                        });

                }} severity='warning' style={{margin: '0.12em'}}/>
                <Button onClick={() => {confirmPageDelete(data)}} label={<i className='pi pi-times-circle'/>} severity='danger'
                        style={{margin: '0.12em'}}/>
            </>
        )
    }

    const galleriesAction = (data) => {
        return(
            <>
                <Button severity='info' onClick={ () => { window.open('/gallery?id=' + data.id).focus() } } style={{ margin: '0.1em' }} label={<i className='pi pi-eye'/>} size='small' />
                <Button severity='warning' style={{ margin: '0.1em' }} label={<i className='pi pi-pencil'/>} size='small' onClick={() => {
                    window.localStorage.setItem('currentGalleryId', data.id)
                    setImageUploadModal(true)
                }} />
                {/*<Button disabled severity='danger' style={{ margin: '0.1em' }} label={<i className='pi pi-times-circle'/>} size='small' />*/}
            </>
        )
    }

    const accesslabel = () => {
        if(mode === 'master') {
            return (<>
                        <Tag style={{ margin: '0.5em' }}>Master-доступ</Tag>
                    </>)
        } else if(mode === 'supervisor') {
            return (<Tag style={{ margin: '0.5em' }}>Головний адміністратор</Tag>)
        } else if(mode === 'admin') {
            return (<Tag style={{ margin: '0.5em' }}>Адміністратор</Tag>)
        } else if(mode === 'editor') {
            return (<Tag style={{ margin: '0.5em' }}>Редактор</Tag>)
        }
    }

    const mainSection = () => {
        if( mode === 'master' || mode === 'supervisor' || mode === 'admin' || mode === 'editor'){
            return(
                <TabPanel header="Головна">
                    <Tag severity='info' style={{ margin: '0.5em' }}>{name}</Tag>
                    { accesslabel() }

                </TabPanel>
            )
        }
    }
    const newsSection = () => {
        if( mode === 'master' || mode === 'supervisor' || mode === 'admin' || mode === 'editor'){
            fetchNews()
            if(fetched1 === false){
                return(
                        <TabPanel header="Новини">
                            <Tag severity='info' style={{ margin: '0.5em' }}>{name}</Tag>
                            { accesslabel() }
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10em'}}>
                                <i><img className='pi-spin' src={logo} height='150px'/></i>
                                Обробка даних, зачекайте
                            </div>
                        </TabPanel>
                )
            } else{
                return(
                    <TabPanel header="Новини">
                        <Tag severity='info' style={{ margin: '0.5em' }}>{name}</Tag>
                        { accesslabel() }
                        <BlockUI blocked={uiBlock}>
                            <p className="m-0">
                                <SpeedDial onClick={() => { setNewsEditorModalTitle(''); setNewsEditorModalDate(new Date().toLocaleDateString("en-IN")); setNewsEditorModalShort(''); setNewsEditorModalText(''); setNewsEditorModal(true)}} radius={120} type="quarter-circle" direction="down-right" style={{ right: 30, top: 30 }} buttonClassName="p-button-help" />
                                <DataTable value={newsItems} tableStyle={{minWidth: '50rem'}}>
                                    <Column field="title" header="Назва"></Column>
                                    <Column field="date" header="Дата публікації"></Column>
                                    <Column field="author" header="Автор"></Column>
                                    <Column body={createStatusLabel} header="Статус"></Column>
                                    <Column body={newsActions} header="Дії"></Column>
                                </DataTable>
                            </p>
                        </BlockUI>
                    </TabPanel>
                )
            }
        }
    }

    const galleriesSection = () => {
        if( mode === 'master' || mode === 'supervisor' || mode === 'admin' || mode === 'editor'){
            fetchGalleries()
            if(fetched6 === false){
                return(
                    <TabPanel header="Галереї">
                        <Tag severity='info' style={{ margin: '0.5em' }}>{name}</Tag>
                        { accesslabel() }
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10em'}}>
                            <i><img className='pi-spin' src={logo} height='150px'/></i>
                            Обробка даних, зачекайте
                        </div>
                    </TabPanel>
                )
            } else{
                return(
                    <TabPanel header="Галереї">
                        <Tag severity='info' style={{ margin: '0.5em' }}>{name}</Tag>
                        { accesslabel() }
                        <BlockUI blocked={uiBlock}>
                            <p className="m-0">
                                <SpeedDial onClick={() => { setGalleriesEditorModal(true)}} radius={120} type="quarter-circle" direction="down-right" style={{ right: 30, top: 30 }} buttonClassName="p-button-help" />
                                <DataTable value={galleries} tableStyle={{minWidth: '50rem'}}>
                                    <Column field="name" header="Назва"></Column>
                                    <Column body={galleriesAction} header="Дії"></Column>
                                </DataTable>
                            </p>
                        </BlockUI>
                    </TabPanel>
                )
            }
        }
    }
    const pagesSection = () => {
        if( mode === 'master' || mode === 'supervisor' || mode === 'admin' || mode === 'editor'){
            fetchPages()
            if(fetched2 === false){
                return(
                    <TabPanel header="Сторінки">
                        <Tag severity='info' style={{ margin: '0.5em' }}>{name}</Tag>
                        { accesslabel() }
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10em'}}>
                            <i><img className='pi-spin' src={logo} height='150px'/></i>
                            Обробка даних, зачекайте
                        </div>
                    </TabPanel>
                )
            } else {
                return (
                    <TabPanel header="Сторінки">
                        <Tag severity='info' style={{margin: '0.5em'}}>{name}</Tag>
                        {accesslabel()}
                        <BlockUI blocked={uiBlock}>
                            <p className="m-0">
                                <SpeedDial onClick={() => {
                                    setPagesEditorModalTitle('');
                                    setPagesEditorModalLink('');
                                    setPagesEditorModalText('');
                                    setPagesEditorModal(true)
                                }} radius={120} type="quarter-circle" direction="down-right"
                                           style={{right: 30, top: 30}} buttonClassName="p-button-help"/>
                                <DataTable value={pages} tableStyle={{minWidth: '50rem'}}>
                                    <Column field="title" header="Назва"></Column>
                                    <Column field="link" header="Посилання"></Column>
                                    <Column body={pagesActions} header="дії"></Column>
                                </DataTable>
                            </p>
                        </BlockUI>
                    </TabPanel>
                )
            }
        }
    }
    const menuSection = () => {
        if( mode === 'master' || mode === 'supervisor' || mode === 'admin' ){
            fetchMenu()
            if(fetched3 === false){
                return(
                    <TabPanel header="Меню">
                        <Tag severity='info' style={{ margin: '0.5em' }}>{name}</Tag>
                        { accesslabel() }
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10em'}}>
                            <i><img className='pi-spin' src={logo} height='150px'/></i>
                            Обробка даних, зачекайте
                        </div>
                    </TabPanel>
                )
            } else {
                return (
                    <TabPanel header="Меню">
                        <Tag severity='info' style={{margin: '0.5em'}}>{name}</Tag>
                        {accesslabel()}
                        <BlockUI blocked={uiBlock}>
                            {menu.map((menuItem) => {
                                if (menuItem.displayName === 'Головна' || menuItem.displayName === 'Новини') {
                                    return (<p style={{color: 'gray'}}>{menuItem.displayName}</p>)
                                } else if (menuItem.type === 'link') {
                                    return (
                                        <>
                                            <p>{menuItem.displayName} <i style={{color: '#3B82F6'}}
                                                                         className='pi pi-cog' onClick={() => {
                                                handleMenuLink(menuItem.id, menuItem.displayName, menuItem.link)
                                            }}/><i style={{color: 'red', marginLeft: '0.3em'}}
                                                   className='pi pi-times-circle' onClick={() => {
                                                deleteMenuItem(menuItem.id)
                                            }}/></p>
                                        </>
                                    )
                                } else if (menuItem.type === 'list') {
                                    return (
                                        <>
                                            <p>{menuItem.displayName} <i style={{color: '#3B82F6'}}
                                                                         className='pi pi-cog' onClick={() => {
                                                handleMenuList(menuItem.id, menuItem.displayName)
                                            }}/> <i style={{color: '#3B82F6'}} className='pi pi-plus' onClick={() => {
                                                handleMenuListAddItem(menuItem.id)
                                            }}/><i style={{color: 'red', marginLeft: '0.3em'}}
                                                   className='pi pi-times-circle' onClick={() => {
                                                deleteMenuItem(menuItem.id)
                                            }}/></p>
                                            <div style={{marginLeft: '1em'}}>
                                                {menuItem['items'].map((listItem) => {
                                                    return (
                                                        <p>{listItem.displayName} <i style={{color: '#3B82F6'}}
                                                                                     className='pi pi-cog'
                                                                                     onClick={() => {
                                                                                         handleMenuListItem(listItem.id, listItem.displayName, listItem.link)
                                                                                     }}/><i
                                                            style={{color: 'red', marginLeft: '0.3em'}}
                                                            className='pi pi-times-circle' onClick={() => {
                                                            deleteMenuItem(listItem.id)
                                                        }}/></p>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    )
                                }
                            })}
                            <i style={{color: '#3B82F6'}} className='pi pi-plus' onClick={() => {
                                handleMenuAddItem()
                            }}/>
                        </BlockUI>
                    </TabPanel>
                )
            }
        }
    }

    const usersSection = () => {
        if( mode === 'master' || mode === 'supervisor' ){
            return(
                <TabPanel disabled header="Користувачі">
                    <p className="m-0">
                        -
                    </p>
                </TabPanel>
            )
        }
    }

    const settingsSection = () => {
        if( mode === 'master' || mode === 'supervisor'  ){
            return(
                <TabPanel disabled header="Налаштування">
                    <p className="m-0">
                        -
                    </p>
                </TabPanel>
            )
        }
    }

    const masterSection = () => {
        if( mode === 'master'  ){
            return(
                <TabPanel disabled header="Системні налаштування">
                    <p className="m-0">
                        -
                    </p>
                </TabPanel>
            )
        }
    }

    const handleImageUpload = () => {
        setFileUid(uid())
        setImageUploadModal(true)
    }

    const handleImageUploadComplete = () => {
        setImageUploadModal(false)
    }

    const renderCustomToolbar = () => {
        return(
            <span className="ql-formats">
                <select className="ql-header" />
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Underline"></button>
                <button className="ql-image" aria-label="Underline"></button>
                {/*<button onClick={handleImageUpload} style={{ marginLeft: '0.5em' }}><i className="pi pi-image" /></button>*/}
            </span>
        )
    }

    const editorHeader = renderCustomToolbar()

    if(connected === true){
        if(mode === 'teacher'){
            return(
                <center style={{ marginTop: '20vh' }}>
                    <Avatar style={{ height: '3.5em', width: '3.5em', border: 'solid 1px black' }} size='xlarge' image='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi71bX5Zc4q07uE08t1RvUodmEKdqUGtZYvpjZHRBo0sLZn3Jr4wACr42pDUSVbu4mb7Y&usqp=CAU' shape="circle" />
                    <h2>{ name }</h2>
                   <h3><p>Вітаємо в адміністративній панелі!<br />Ви зможете керувати сторінками, як тільки вам нададуть доступ.</p></h3>
                </center>
            )
        }
        if(auth === true) {
            return (
                    <div>
                        <ConfirmDialog />
                        <Dialog draggable={false} header="Додати фото" visible={imageUploadModal} style={{ width: '50vw' }} onHide={() => setImageUploadModal(false)}>
                            <FileUpload onChange={() => {  }} name='upload' url={serverUrl + '/uploadToGallery?id=' + String(window.localStorage.getItem('currentGalleryId'))} mode='advanced' multiple accept="image/*" maxFileSize={1500000} emptyTemplate={<p className="m-0">Перетягніть файли сюди для завантаження</p>} onUpload={() => { window.location.reload() }} />
                        </Dialog>
                        <Dialog draggable={false} header="Створення галереї" visible={galleriesEditorModal} style={{ width: '50vw' }} onHide={() => setGalleriesEditorModal(false)}>
                            <div className="card">
                                <InputText placeholder='Назва' value={galleriesEditorModalName} onChange={(e) => setGalleriesEditorModalName(e.target.value)} />
                                <Button onClick={() => {
                                    axios.post(serverUrl + '/galleryCreate?name=' + galleriesEditorModalName, {
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token'),
                                        name: galleriesEditorModalName
                                    })
                                        .then((result) => {
                                            window.location.reload()
                                        })
                                }} style={{ float: 'right', marginTop: '2em' }} label="Далі" />
                            </div>
                        </Dialog>
                        <Dialog draggable={false} header="Створення новини" visible={newsEditorModal} style={{ width: '50vw' }} onHide={() => setNewsEditorModal(false)}>
                            <div className="card">
                                <InputText placeholder='Назва' value={newsEditorModalTitle} onChange={(e) => setNewsEditorModalTitle(e.target.value)} /><InputText placeholder='Дата' value={newsEditorModalDate} onChange={(e) => setNewsEditorModalDate(e.target.value)} />
                                <InputText placeholder='Короткий опис' style={{ width: '100%', marginTop: '0.25em', marginBottom: '0.25em' }} value={newsEditorModalShort} onChange={(e) => setNewsEditorModalShort(e.target.value)} />
                                <Editor headerTemplate={editorHeader} value={newsEditorModalText} onTextChange={(e) => setNewsEditorModalText(e.htmlValue)} style={{ height: '320px' }} />
                                <Button onClick={() => {
                                    axios.post(serverUrl + '/articleCreate', {
                                        title: newsEditorModalTitle.replace("'", "*"),
                                        date: newsEditorModalDate,
                                        subtitle: newsEditorModalShort.replace("'", "*"),
                                        content: newsEditorModalText.replace("'", "*"),
                                        author: name,
                                        gallery: 'null',
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                        .then(() => {
                                            setNewsEditorModal(false)
                                            window.location.reload()
                                        })
                                }} style={{ float: 'right', marginTop: '2em' }} label="Опублікувати" />
                            </div>
                        </Dialog>
                        <Dialog draggable={false} header="Редагування новини" visible={newsEditorEditModal} style={{ width: '50vw' }} onHide={() => setNewsEditorEditModal(false)}>
                            <div className="card">
                                <InputText placeholder='Назва' value={newsEditorModalTitle} onChange={(e) => setNewsEditorModalTitle(e.target.value)} /><InputText placeholder='Дата' value={newsEditorModalDate} onChange={(e) => setNewsEditorModalDate(e.target.value)} />
                                <InputText tooltip="Код папки google drive" value={newsEditorModalGallery} onChange={(e) => setNewsEditorModalGallery(e.target.value)} placeholder="Галерея" />
                                <InputText placeholder='Короткий опис' style={{ width: '100%', marginTop: '0.25em', marginBottom: '0.25em' }} value={newsEditorModalShort} onChange={(e) => setNewsEditorModalShort(e.target.value)} />
                                <Editor headerTemplate={editorHeader} value={newsEditorModalText} onTextChange={(e) => setNewsEditorModalText(e.htmlValue)} style={{ height: '320px' }} />
                                <Button onClick={() => {
                                    axios.patch(serverUrl + '/articleUpdate', {
                                        gallery: newsEditorModalGallery,
                                        id: newsEditorModalEditId,
                                        title: newsEditorModalTitle.replace("'", "*"),
                                        date: newsEditorModalDate,
                                        subtitle: newsEditorModalShort.replace("'", "*"),
                                        content: newsEditorModalText.replace("'", "*"),
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token'),
                                    })
                                        .then(() => {
                                            setNewsEditorEditModal(false)
                                            window.location.reload()
                                        })
                                }} style={{ float: 'right', marginTop: '2em' }} label="Зберегти" />
                            </div>
                        </Dialog>
                        <Dialog draggable={false} header="Створення сторінки" visible={pagesEditorModal} style={{ width: '50vw' }} onHide={() => setPagesEditorModal(false)}>
                            <div className="card">
                                <InputText placeholder='Назва' value={pagesEditorModalTitle} onChange={(e) => setPagesEditorModalTitle(e.target.value)} />
                                <InputText placeholder='Посилання' value={pagesEditorModalLink} onChange={(e) => setPagesEditorModalLink(e.target.value)} />
                                <Editor headerTemplate={editorHeader} value={pagesEditorModalText} onTextChange={(e) => setPagesEditorModalText(e.htmlValue)} style={{ height: '320px' }} />
                                <Button onClick={() => {
                                    setPagesEditorModal(false)
                                    setUiBlock(true)
                                    axios.post(serverUrl + '/pageCreate', {
                                        title: pagesEditorModalTitle.replace("'", "*"),
                                        link: pagesEditorModalLink,
                                        content: pagesEditorModalText.replace("'", "*"),
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                        .then(() => {
                                            setUiBlock(false)
                                            window.location.reload()
                                        })
                                }} style={{ float: 'right', marginTop: '2em' }} label="Зберегти" />
                            </div>
                        </Dialog>
                        <Dialog draggable={false} header="Редагування сторінки" visible={pagesEditorEditModal} style={{ width: '50vw' }} onHide={() => setPagesEditorEditModal(false)}>
                            <div className="card">
                                <InputText placeholder='Назва' value={pagesEditorModalTitle} onChange={(e) => setPagesEditorModalTitle(e.target.value)} />
                                <Editor headerTemplate={editorHeader} value={pagesEditorModalText} onTextChange={(e) => setPagesEditorModalText(e.htmlValue)} style={{ height: '320px' }} />
                                <Button onClick={() => {
                                    setPagesEditorEditModal(false)
                                    setUiBlock(true)
                                    axios.patch(serverUrl + '/pageUpdate', {
                                        id: pagesEditorModalEditId,
                                        title: pagesEditorModalTitle.replace("'", "*"),
                                        content: pagesEditorModalText.replace("'", "*"),
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token'),
                                    })
                                        .then(() => {
                                            setUiBlock(false)
                                            window.location.reload()
                                        })
                                }} style={{ float: 'right', marginTop: '2em' }} label="Зберегти" />
                            </div>
                        </Dialog>
                        <Dialog draggable={false} header="Створення елементу меню" visible={menuItemAddSelectTypeModal} style={{ width: '50vw' }} onHide={() => setMenuItemAddSelectTypeModal(false)}>
                            <h4>Оберіть тип елементу:</h4>
                            <Button severity='info' size='small' style={{ margin: '0.3em' }} onClick={() => {
                                setMenuItemAddSelectTypeModal(false)
                                setMenuItemAddLinkModal(true)
                            }}>Посилання</Button><Button severity='info' size='small' style={{ margin: '0.3em' }} onClick={() => {
                            setMenuItemAddSelectTypeModal(false)
                            setMenuItemAddListModal(true)
                        }}>Розділ</Button>
                        </Dialog>
                        <Dialog draggable={false} header="Створення посилання" visible={menuItemAddLinkModal} style={{ width: '50vw' }} onHide={() => setMenuItemAddLinkModal(false)}>
                            <InputText required style={{ margin: '0.3em' }} placeholder='Назва' value={menuLinkEditorTitle} onChange={(e) => setMenuLinkEditorTitle(e.target.value)} />
                            <InputText required style={{ margin: '0.3em' }} placeholder='/посилання' value={menuLinkEditorUri} onChange={(e) => setMenuLinkEditorUri(e.target.value)} />
                            <Button type='submit' style={{ margin: '0.3em' }} onClick={() => {
                                if(menuLinkEditorTitle.length > 0 && menuLinkEditorUri.length > 0) {
                                    setMenuItemAddLinkModal(false)
                                    setUiBlock(true)
                                    axios.post(serverUrl + '/menuAddLink', {
                                        title: menuLinkEditorTitle,
                                        link: menuLinkEditorUri,
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                    function reloadScreen() {
                                        window.location.reload()
                                    }
                                    setTimeout(reloadScreen, 1000);
                                }
                            }}>Створити</Button>
                        </Dialog>
                        <Dialog draggable={false} header="Створення розділу" visible={menuItemAddListModal} style={{ width: '50vw' }} onHide={() => setMenuItemAddListModal(false)}>
                            <InputText required style={{ margin: '0.3em' }} placeholder='Назва' value={menuLinkEditorTitle} onChange={(e) => setMenuLinkEditorTitle(e.target.value)} />
                            <Button type='submit' style={{ margin: '0.3em' }} onClick={() => {
                                if(menuLinkEditorTitle.length > 0) {
                                    setMenuItemAddLinkModal(false)
                                    setUiBlock(true)
                                    axios.post(serverUrl + '/menuAddList', {
                                        title: menuLinkEditorTitle,
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                    function reloadScreen() {
                                        window.location.reload()
                                    }
                                    setTimeout(reloadScreen, 1000);
                                }
                            }}>Створити</Button>
                        </Dialog>
                        <Dialog draggable={false} header="Редагування посилання" visible={menuItemEditLinkModal} style={{ width: '50vw' }} onHide={() => setMenuItemEditLinkModal(false)}>
                            <InputText required style={{ margin: '0.3em' }} placeholder='Назва' value={menuLinkEditorTitle} onChange={(e) => setMenuLinkEditorTitle(e.target.value)} />
                            <InputText required style={{ margin: '0.3em' }} placeholder='/посилання' value={menuLinkEditorUri} onChange={(e) => setMenuLinkEditorUri(e.target.value)} />
                            <Button type='submit' style={{ margin: '0.3em' }} onClick={() => {
                                if(menuLinkEditorTitle.length > 0 && menuLinkEditorUri.length > 0) {
                                    setMenuItemAddLinkModal(false)
                                    setUiBlock(true)
                                    axios.patch(serverUrl + '/menuEditLink', {
                                        id: window.sessionStorage.getItem('currentId'),
                                        title: menuLinkEditorTitle,
                                        link: menuLinkEditorUri,
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                    function reloadScreen() {
                                        window.location.reload()
                                    }
                                    setTimeout(reloadScreen, 1000);
                                }
                            }}>Зберегти</Button>
                        </Dialog>
                        <Dialog draggable={false} header="Редагування розділу" visible={menuItemEditListModal} style={{ width: '50vw' }} onHide={() => setMenuItemEditListModal(false)}>
                            <InputText required style={{ margin: '0.3em' }} placeholder='Назва' value={menuLinkEditorTitle} onChange={(e) => setMenuLinkEditorTitle(e.target.value)} />
                            <Button type='submit' style={{ margin: '0.3em' }} onClick={() => {
                                if(menuLinkEditorTitle.length > 0) {
                                    setMenuItemAddLinkModal(false)
                                    setUiBlock(true)
                                    axios.patch(serverUrl + '/menuEditListItem', {
                                        id: window.sessionStorage.getItem('currentId'),
                                        title: menuLinkEditorTitle,
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                    function reloadScreen() {
                                        window.location.reload()
                                    }
                                    setTimeout(reloadScreen, 1000);
                                }
                            }}>Зберегти</Button>
                        </Dialog>
                        <Dialog draggable={false} header="Створення посилання у розділі" visible={menuListItemAddLinkModal} style={{ width: '50vw' }} onHide={() => setMenuListItemAddLinkModal(false)}>
                            <InputText required style={{ margin: '0.3em' }} placeholder='Назва' value={menuLinkEditorTitle} onChange={(e) => setMenuLinkEditorTitle(e.target.value)} />
                            <InputText required style={{ margin: '0.3em' }} placeholder='/посилання' value={menuLinkEditorUri} onChange={(e) => setMenuLinkEditorUri(e.target.value)} />
                            <Button type='submit' style={{ margin: '0.3em' }} onClick={() => {
                                if(menuLinkEditorTitle.length > 0 && menuLinkEditorUri.length > 0) {
                                    setMenuItemAddLinkModal(false)
                                    setUiBlock(true)
                                    axios.post(serverUrl + '/menuAddListItem', {
                                        id: window.sessionStorage.getItem('currentId'),
                                        title: menuLinkEditorTitle,
                                        link: menuLinkEditorUri,
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                    function reloadScreen() {
                                        window.location.reload()
                                    }
                                    setTimeout(reloadScreen, 1000);
                                }
                            }}>Створити</Button>
                        </Dialog>
                        <Dialog draggable={false} header="Редагування посилання у розділі" visible={menuListItemEditLinkModal} style={{ width: '50vw' }} onHide={() => setMenuListItemEditLinkModal(false)}>
                            <InputText required style={{ margin: '0.3em' }} placeholder='Назва' value={menuLinkEditorTitle} onChange={(e) => setMenuLinkEditorTitle(e.target.value)} />
                            <InputText required style={{ margin: '0.3em' }} placeholder='/посилання' value={menuLinkEditorUri} onChange={(e) => setMenuLinkEditorUri(e.target.value)} />
                            <Button type='submit' style={{ margin: '0.3em' }} onClick={() => {
                                if(menuLinkEditorTitle.length > 0 && menuLinkEditorUri.length > 0) {
                                    setMenuItemAddLinkModal(false)
                                    setUiBlock(true)
                                    axios.patch(serverUrl + '/menuEditListItem', {
                                        id: window.sessionStorage.getItem('currentId'),
                                        title: menuLinkEditorTitle,
                                        link: menuLinkEditorUri,
                                        sessionId: window.localStorage.getItem('sessionId'),
                                        token: window.localStorage.getItem('token')
                                    })
                                    function reloadScreen() {
                                        window.location.reload()
                                    }
                                    setTimeout(reloadScreen, 1000);
                                }
                            }}>Зберегти</Button>
                        </Dialog>
                        <TabView>
                            { newsSection() }
                            {/*{ galleriesSection() }*/}
                            { pagesSection() }
                            { menuSection() }
                            { usersSection() }
                            { settingsSection() }
                            { masterSection() }
                        </TabView>
                    </div>
                )
        }  else{
            return(
                <div style={{ textAlign: 'center', marginTop: '8em' }}>
                    <form action='/administration' autocomplete="off">
                        <h2>Авторизація</h2>
                        <input hidden name='loginData' value='yes'/>
                        <span style={{ margin: '0.25em' }} className="p-input-icon-left">
                        <i className="pi pi-user" />
                        <InputText required name='username' placeholder="Логін" />
                    </span>
                        <br />
                        <span style={{ margin: '0.25em' }} className="p-input-icon-left">
                        <i className="pi pi-lock" />
                        <InputText required name='password' type='password' placeholder="Пароль" />
                    </span>
                        <br />
                        <Button style={{ margin: '0.5em' }} type='submit'>Увійти</Button>
                    </form>
                </div>
            )
        }
    } else{
        return(
            <>
                Завантаження...
            </>
        )
    }
}

export default Admin