import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Admin from "./components/sys/Admin";

const root = ReactDOM.createRoot(document.getElementById('root'));
document.documentElement.lang = "ua";
if(window.location.pathname.split('/')[1] === 'administration'){
    root.render(
        <Admin />
    );
} else{
    root.render(
        <App />
    )
}

