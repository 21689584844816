import React, { useState } from "react";
import './App.css'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css"
import "primeflex/themes/primeone-light.css"
import logo from './res/img/logo.png'
import facebook from './res/img/facebook.png'
import instagram from './res/img/instagram.png'
import youtube from './res/img/youtube.png'
import trizub from './res/img/trizub.png'
import arrow from './res/img/arrow.png'

import Menu from './components/ui/Menu'
import Home from "./components/staticPages/Home";

import {BrowserRouter, Routes, Route} from "react-router-dom";
import News from "./components/staticPages/News";
import NotFound from "./components/staticPages/NotFound";

import { serverUrl } from "./components/sys/Api";

import PageRender from "./components/sys/PageRender";
import Admin from "./components/sys/Admin";
import MenuDesktop from "./components/ui/MenuDesktop";
import PageRenderDesktop from "./components/sys/PageRenderDesktop";
import NewsDesktop from "./components/staticPages/NewsDesktop";
import GalleryRender from "./components/sys/GalleryRender";

function App() {

    const version = '1.5.1'

    const [connection, setConnection] = useState(false);

    fetch(serverUrl)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setConnection(true)
        })
        .catch(error => {
            setConnection(false)
        });

    if(window.location.host[0] === 'a' && window.location.host[1] === '.'){
        return(
            <>
                <Admin />
            </>
        )
    } else {
        if (connection === true) {
            return (
                <>
                    {/*<div style={{ position: 'fixed', left: 10, bottom: 10 }}>*/}
                    {/*    Debug: on<br />*/}
                    {/*    Connection: <span style={{ color: 'green' }}>{ String(connection) }</span>*/}
                    {/*</div>*/}

                    {/* Mobile */}
                    <div className="md:hidden">
                        <BrowserRouter>
                            <div style={{
                                width: '104.3%',
                                height: '4em',
                                backgroundColor: 'white',
                                marginLeft: '-2.1%',
                                marginTop: '-0.5em'
                            }}/>
                            <div style={{zIndex: '999'}} className="header">
                                <div style={{float: 'left', marginLeft: '1em', marginTop: '1.5em'}}><Menu/></div>
                                <div style={{float: 'left', marginLeft: '1em', marginTop: '1em'}}><span>ШКОЛА<br/> №216</span>
                                </div>{/*<img src={pumpkin} width='20px' style={{ marginTop: '2em', marginLeft: '-1em' }} />*/}
                                <div style={{float: 'right', marginRight: '1em', marginTop: '0.5em'}}><img src={logo}
                                                                                                           height='50em'/>
                                </div>
                            </div>
                            <div>
                                <Routes>
                                    <Route path='/' element={<Home/>}/>
                                    <Route path='/news' element={<News/>}/>
                                    <Route path='/gallery' element={<GalleryRender/>}/>
                                    <Route path='/:pageId' element={<PageRender/>}/>
                                    <Route path='*' element={<NotFound/>}/>
                                </Routes>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                                 className="footer">
                                <div style={{width: '27%', display: 'flex', alignItems: 'center'}}>
                                    <a href='https://www.mon.gov.ua/'><img src={trizub} height='40px'
                                                                           style={{marginLeft: '1.65em'}}/></a>
                                </div>
                                <div style={{
                                    width: '46%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}>
                                    <a href='https://www.instagram.com/school_216_obolon/'><img src={instagram}
                                                                                                height='40px'/></a>
                                    <a href='https://www.facebook.com/profile.php?id=100092723934274'><img src={facebook}
                                                                                                           height='40px'/></a>
                                    <a href='https://www.youtube.com/@School216Obolon'><img src={youtube} height='40px'/></a>
                                </div>
                                <div style={{
                                    width: '27%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'right'
                                }}>
                                    <a onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}><img src={arrow} height='35px' style={{marginRight: '1.2em'}}/></a>
                                </div>
                            </div>
                        </BrowserRouter>
                    </div>
                    {/* Mobile */}

                    {/* Desktop */}
                    <div style={{marginTop: '4em'}} className="hidden md:block">
                        <BrowserRouter>
                            <div style={{zIndex: '999'}} className="header">
                                <div style={{float: 'left', marginLeft: '1em', marginTop: '1.5em'}}><MenuDesktop/></div>
                                <div style={{float: 'left', marginLeft: '1em', marginTop: '1em'}}><span>ШКОЛА<br/> №216</span>
                                </div>
                                {/*<img src={pumpkin} width='20px' style={{ marginTop: '2em', marginLeft: '-1em' }} />*/}
                                <div style={{float: 'right', marginRight: '1em', marginTop: '0.5em'}}><img src={logo}
                                                                                                           height='50em'/>
                                </div>
                            </div>
                            <div>
                                <Routes>
                                    <Route path='/' element={<Home/>}/>
                                    <Route path='/news' element={<NewsDesktop/>}/>
                                    <Route path='/gallery' element={<GalleryRender/>}/>
                                    <Route path='/:pageId' element={<PageRenderDesktop/>}/>
                                    <Route path='*' element={<NotFound/>}/>
                                </Routes>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                                 className="footer-desktop">
                                <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                    <a href='https://www.mon.gov.ua/'><img src={trizub} height='40px'
                                                                           style={{marginLeft: '1.65em'}}/></a>
                                </div>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}>

                                </div>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}>

                                </div>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}>

                                </div>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}>

                                </div>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                }}>
                                    <a href='https://www.instagram.com/school_216_obolon/'><img src={instagram}
                                                                                                height='40px'/></a>
                                    <a href='https://www.facebook.com/profile.php?id=100092723934274'><img src={facebook}
                                                                                                 height='40px'/></a>
                                    <a href='https://www.youtube.com/@School216Obolon'><img src={youtube} height='40px'/></a>
                                    <a onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}><img src={arrow} height='35px' style={{marginRight: '1.2em'}}/></a>
                                </div>
                            </div>
                        </BrowserRouter>
                    </div>
                    {/* Desktop */}
                </>
            )
        } else {
            return (
                <>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '36vh'}}>
                        <i><img className='pi-spin' src={logo} height='150px'/></i>
                    </div>
                </>
            )
        }
    }
}

export default App;
