import React, {useEffect, useState} from "react";

import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import {Card} from "primereact/card";
import {serverUrl} from "../sys/Api";
import NewsViewDesktop from "./NewsViewDesktop";
import logo from "../../res/img/logo.png";

const urlParams = new URLSearchParams(window.location.search);
const urlId = urlParams.get('id');

const NewsDesktop = () => {
    const [renderedItems, setRenderedItems] = useState([]);
    const [newsItems, setNewsItems] = useState([]);
    const [fetched, setFetched] = useState(false);

    if(fetched === false){
        fetch(serverUrl + '/articles')
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setNewsItems(data)
                setFetched(true)
            });
    }

    useEffect(() => {
        setRenderedItems(newsItems)
    }, []);

    const itemTemplate = (data) => {

        return(
                <Card style={{ backgroundColor: '#007EFF', color: 'white', width: '32%', margin: '0.6%' }} title={data.title} subTitle={<div style={{ color: 'white' }}>{data.date}</div>} footer={<div className="flex flex-wrap justify-content-end gap-2">
                    <a href={'/news?id=' + data.id}><Button style={{ backgroundColor: '#FFC000' }} label="Читати"/></a>
                </div>}>
                    <p className="m-0">
                        {data.subtitle}
                    </p>
                </Card>
        )
    }

    if(fetched === true) {
        if(urlId !== null){
            return(
                <NewsViewDesktop />
            )
        } else {
            return (
                <div>
                    <div className="legendNewsDesktop" style={{height: '35em'}}>
                        <p style={{paddingTop: '5em'}}>
                            <span style={{color: 'white', fontSize: '2.5em'}}>Новини</span>
                            <br/>
                            <span style={{color: 'white', fontSize: '2.5em'}}>СШ №216</span>
                        </p>
                    </div>
                    <DataView gutter style={{ width: '100%', marginTop: '0.6%' }} value={newsItems} itemTemplate={itemTemplate} paginator rows={12}/>
                </div>
            )
        }
    }else{
        return (
            <>
                <div style={{ height: '30vh' }} />
                <center>
                    <i><img className='pi-spin' src={logo} height='150px'/></i>
                </center>
                <div style={{ height: '50vh' }} />
            </>
        )
    }
}

export default NewsDesktop