import React, {useState} from "react";
import './newsView.css'
import {serverUrl} from "../sys/Api";
import {Button} from "primereact/button";
import logo from "../../res/img/logo.png";

const NewsView = () => {
    const [article, setArticle] = useState([]);
    const [fetched, setFetched] = useState(false);

    const [previewArticle, setPreviewArticle] = useState([]);
    const [previewFetched, setPreviewFetched] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const urlId = urlParams.get('id');

    if(fetched === false) {
        fetch(serverUrl + '/articles/' + urlId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setArticle(data[0])
                setFetched(true)
            });
    }

    if(previewFetched === false) {
        fetch(serverUrl + '/articlesPreview/' + window.location.pathname.split('/')[2])
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setPreviewArticle(data[0])
                setPreviewFetched(true)
            });
    }

    if(fetched === true && previewFetched === true) {
        if(article !== undefined || previewArticle !== undefined){
                if(article !== undefined) {
                    if(article.gallery !== null) {
                        return (
                            <>
                                <div className="legendNews" style={{height: 'min-content'}}>
                                    <p style={{paddingTop: '1em', paddingBottom: '1em'}}>
                                        <span style={{color: 'white', fontSize: '2em'}}>{article.title}</span>
                                        <br/>
                                        <span style={{color: 'white', fontSize: '1.5em'}}>{article.date}</span>
                                    </p>
                                </div>
                                <div className='newsView-container'
                                     dangerouslySetInnerHTML={{__html: article.content}}/>
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    <Button onClick={() => {
                                        window.open('https://drive.google.com/embeddedfolderview?id=' + article.gallery + '#grid')
                                    }} style={{marginBottom: '1.5em'}}>Переглянути усі фото</Button>
                                </div>
                            </>
                        )
                    } else{
                        return (
                            <>
                                <div className="legendNews" style={{height: 'min-content'}}>
                                    <p style={{paddingTop: '1em', paddingBottom: '1em'}}>
                                        <span style={{color: 'white', fontSize: '2em'}}>{article.title}</span>
                                        <br/>
                                        <span style={{color: 'white', fontSize: '1.5em'}}>{article.date}</span>
                                    </p>
                                </div>
                                <div className='newsView-container'
                                     dangerouslySetInnerHTML={{__html: article.content}}/>
                            </>
                        )
                    }
                } else{
                    window.location.replace('/')
                }

        } else{
            window.location.replace('/')
        }

    }else{
        return (
            <>
                <div style={{ height: '30vh' }} />
                <center>
                    <i><img className='pi-spin' src={logo} height='150px'/></i>
                </center>
                <div style={{ height: '50vh' }} />
            </>
        )
    }
}

export default NewsView;