import React, {useState} from "react";
import {serverUrl} from "./Api";
import logo from "../../res/img/logo.png";

const PageRenderDesktop = () => {
    const [page, setPage] = useState([]);
    const [fetched, setFetched] = useState(false);

    if(fetched === false) {
        fetch(serverUrl + '/pages/' + window.location.pathname.split('/')[1])
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setPage(data[0])
                setFetched(true)
            });
    }

    if(fetched === true) {
        if(page !== undefined){
            return (
                <>
                    <div className="legendPageDesktop" style={{height: 'min-content'}}>
                        <p>
                            <span style={{fontSize: '3em'}}>{page.title}</span>
                        </p>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ minHeight: '85vh' }} className='newsView-containerDesktop' dangerouslySetInnerHTML={{__html: page.content}} />
                    </div>
                </>
            )
        } else{
            window.location.replace('/')
        }

    } else{
        return (
            <>
                <div style={{ height: '30vh' }} />
                <center>
                    <i><img className='pi-spin' src={logo} height='150px'/></i>
                </center>
                <div style={{ height: '50vh' }} />
            </>
        )
    }
}

export default PageRenderDesktop