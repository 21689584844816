import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import 'primeicons/primeicons.css';
import MenuList from "./MenuList";
import { serverUrl } from "../sys/Api";

const Menu = () => {

    const [visible, setVisible] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [fetched, setFetched] = useState(false);
    const setVisibleFalse = () => {
        setVisible(false)
    }

    if(fetched === false) {
        fetch(serverUrl + '/menu')
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setMenuItems(data)
                setFetched(true)
            });
    }

    const createMenu = (item) => {
        if(item.type === 'link') {
            return (
                <a style={{color: 'black', textDecoration: 'none'}} href={item.link} onClick={setVisibleFalse}>
                    <h4>{item.displayName}</h4></a>
            )
        } else if (item.type === 'list'){
            return(
                <MenuList item={item} />
            )
        }
    }

    if(fetched === true){
        return (
            <div className="card flex justify-content-center">
                <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
                    <h2 style={{ marginTop: '-0.2em' }}>Меню</h2>
                    { menuItems.map((item) => createMenu(item)) }
                </Sidebar>
                <i onClick={() => setVisible(true)} className="pi pi-bars" style={{ fontSize: '2rem', marginTop: '-0.4rem' }} />
            </div>
        )
    }


}

export default Menu;