import React, {useState} from "react";
import {serverUrl} from "./Api";

const GalleryRender = () => {

    let [fetched, setFetched] = useState(false);
    let [images, setImages] = useState(undefined);

    const urlParams = new URLSearchParams(window.location.search);
    const urlId = urlParams.get('id');

    if(fetched === false){
        fetch(serverUrl + '/gallery/' + urlId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setImages(data)
            })
            .then(() => {
                setFetched(true)
            })
    }


    if(fetched === false){
        return(
            <>
                завантаження...
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
            </>
        )
    } else {
        return (<>
            <div className="md:hidden">
                <center>
                { images.map((item) => {
                    return(<img style={{ width: '90%', height: '15em', margin: '0.5em', objectFit: 'cover' }} src={serverUrl + '/images/' + item.id} />)
                } ) }
                </center>
            </div>
            <div className="hidden md:block">
                {/*<center>*/}
                {/*    { images.map((item) => {*/}
                {/*        return(<img style={{ width: '30%', height: '20em', margin: '0.5em', objectFit: 'cover' }} src={serverUrl + '/images/' + item.id} />)*/}
                {/*    } ) }*/}
                {/*</center>*/}
                <div dangerouslySetInnerHTML='<iframe src="https://drive.google.com/embeddedfolderview?id=FOLDER-ID#grid" style="width:100%; height:600px; border:0;"></iframe>' />
            </div>
            </>)
    }
}

export default GalleryRender;